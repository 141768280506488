@font-face {
  font-family: EuclidCircularA;
  font-weight: normal;
  src: url('./assets/fonts/Euclid/EuclidCircularA-Regular.otf')
      format('opentype'),
    url('./assets/fonts/Euclid/EuclidCircularA-Regular.woff') format('woff');
}

@font-face {
  font-family: EuclidCircularA;
  font-weight: 500;
  src: url('./assets/fonts/Euclid/EuclidCircularA-Medium.otf')
      format('opentype'),
    url('./assets/fonts/Euclid/EuclidCircularA-Medium.woff') format('woff');
}

@font-face {
  font-family: EuclidCircularA;
  font-weight: bold;
  src: url('./assets/fonts/Euclid/EuclidCircularA-Bold.otf') format('opentype'),
    url('./assets/fonts/Euclid/EuclidCircularA-Bold.woff') format('woff');
}

@font-face {
  font-family: EuclidCircularA;
  font-weight: 600;
  src: url('./assets/fonts/Euclid/EuclidCircularA-Semibold.otf')
      format('opentype'),
    url('./assets/fonts/Euclid/EuclidCircularA-Semibold.woff') format('woff');
}

* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  font-family: EuclidCircularA, sans-serif;
}

body {
  margin: 0;
}

.portal-root {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
}

.portal-root > * {
  pointer-events: all;
}

#tooltip-root {
  z-index: 1300;
}

#floating-menu-root {
  z-index: 1200;
}

#modal-root {
  z-index: 1100;
}

#drawer-root {
  z-index: 1000;
}

.ScrollPrimary::-webkit-scrollbar {
  width: 8px;
}

.ScrollPrimary::-webkit-scrollbar-track {
  background: white;
}

.ScrollPrimary::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.48);
}

.ScrollPrimary::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.58);
}

/* Comment */
